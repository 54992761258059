import {auth, provider} from "../config/firebase";
import {signInWithPopup} from "firebase/auth";
import {useNavigate} from "react-router-dom";

export const Login = () => {
  const navigate = useNavigate();

  const signInWithGoogle = async () => {
    const result = await signInWithPopup(auth, provider)
    console.log(result);
    navigate('/');
  };

  return <div>
    <div>sign in with Google To Continue</div>
    <button className='m-10 p-4 bg-gray-500 text-black font-bold rounded-3xl shadow-sm hover:shadow-lg hover:bg-white transition-all' onClick={signInWithGoogle}>Sign In With Google</button>
  </div>
};