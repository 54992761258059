import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup'
import {addDoc, collection} from 'firebase/firestore';
import {db, auth} from '../../config/firebase';
import {useAuthState} from 'react-firebase-hooks/auth';
import {useNavigate} from 'react-router-dom';

interface CreateFormData {
  title: string;
  description: string;
}

export const CreateForm = () => {

  const [user] = useAuthState(auth);
  const navigate = useNavigate();

  const schema = yup.object().shape({
    title: yup.string().required("You must add a title."),
    description: yup.string().required("You must add a description."),
  });

  const { register, handleSubmit, formState: { errors } } = useForm<CreateFormData>({
    resolver: yupResolver(schema),
  });

  const postsRef = collection(db, 'posts');

  const onCreatePost = async (data: CreateFormData) => {
    console.log(data);
    await addDoc(postsRef, {
      ...data,
      username: user?.displayName,
      userId: user?.uid,
    })

    navigate('/');
  };

  return (
  <div className="grid grid-cols-3 gap-4">
    <div className=""></div>
    <div className="bg-blue-500 m-4 rounded-3xl" >
      <form onSubmit={handleSubmit(onCreatePost)}>
      <input className="m-4 rounded-lg" placeholder="Title..." {...register("title")} />
      <p className="text-red-500">{errors.title?.message}</p>
      <textarea className="m-4 rounded-lg" placeholder="Description..." {...register("description")} />
      <p className="text-red-500 ">{errors.description?.message}</p>
      <input className="bg-gray-200 p-4 m-4 rounded-lg shadow-sm hover:shadow-3xl hover:bg-gray-500 transition-all" type="submit"/>
      </form>
    </div>
    <div className=""></div>
  </div>)
};