import {Link} from 'react-router-dom';
import {auth} from '../config/firebase';
import {useAuthState} from 'react-firebase-hooks/auth';
import {signOut} from 'firebase/auth';
import {useNavigate} from 'react-router-dom';

export const Navbar = () => {
  const [user] = useAuthState(auth);
  const navigate = useNavigate();

  const signUserOut = async () => {
    await signOut(auth);
    navigate('/');
  };

  return <div className="flex flex-row">
    <div className='bg-blue-500 flex-1 flex text-3xl'>
    <Link to="/" className='m-6 bg-gray-500 flex-1 rounded-3xl font-bold shadow-sm hover:bg-white hover:shadow-lg transition-all'>Home</Link>
    {!user ? <Link to="/login" className='m-6 bg-gray-500 flex-1 rounded-3xl font-bold shadow-sm hover:bg-white hover:shadow-lg transition-all'>Login</Link> : <Link to="/createpost" className='m-6 bg-gray-500 flex-1 rounded-3xl font-bold shadow-sm hover:bg-white hover:shadow-lg transition-all'>Create Post</Link>}
    </div>
    
    <div className='bg-violet-500 flex flex-1'>
      <div className='flex-1 font-3xl font-bold'>{user?.displayName}</div>
      <div className="flex-1 text-center"><img src={user?.photoURL || ''} className='h-20 rounded-full'/></div>
      <button className="flex-1 bg-gray-500 p-4 m-4 rounded-3xl font-bold text-2xl shadow-sm hover:bg-white hover:shadow-lg transition-all" onClick={signUserOut}>Log Out</button>
    </div>
  </div>
}