import React from 'react';
import './App.css';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {Navbar} from './components/navbar';
import {Main} from './pages/main/main';
import {Login} from './pages/login';
import {CreatePost} from './pages/create-post/create-post';

function App() {
  return (
    <div className="text-center bg-slate-600 h-full">
      <Router>
        <Navbar /> 
        <Routes>
          <Route path="/" element={<Main />}/>
          <Route path="/login" element={<Login />}/>
          <Route path="/createpost" element={<CreatePost />}/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
